<template>
  <div class="register">
    <Header />
    <b-container class="register-container">
      <b-row class="justify-content-center">
        <div>
          <b-card-group deck>
            <b-card>
              <h1>Register</h1>
              <div class="error my-2" v-if="emtyForm">
                <p>Form harus diisi.</p>
              </div>
              <template>
                <div>
                  <form action="" method="POST" @submit.prevent="handleSubmit">
                    <h3 class="login-label">Email</h3>
                    <vs-input
                      id="email"
                      type="email"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="email"
                      required
                    />
                    <div class="error my-2" v-if="!$v.email.email">
                      <p>Format email belum sesuai</p>
                    </div>
                    <div class="error my-2" v-if="emailIsExist">
                      <p>Email sudah terdaftar. Silahkan lakukan login.</p>
                    </div>

                    <h3 class="login-label">No. Hp</h3>
                    <vs-input
                      id="mobile_phone"
                      type="number"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="mobile_phone"
                      required
                    />

                    <div class="error my-2" v-if="phoneIsExist">
                      <p>No. Hp sudah terdaftar.</p>
                    </div>
                    <div class="error my-2" v-if="phoneAndEmailIsExist">
                      <p>Email dan No. Hp sudah terdaftar.</p>
                    </div>

                    <div class="error my-2" v-if="!$v.mobile_phone.minLength">
                      <p>
                        No. Hp minimal
                        {{ $v.mobile_phone.$params.minLength.min }} digit.
                      </p>
                    </div>

                    <div class="error my-2" v-if="!$v.mobile_phone.maxLength">
                      <p>
                        No. Hp maksimal
                        {{ $v.mobile_phone.$params.maxLength.max }} digit.
                      </p>
                    </div>

                    <h3 class="login-label">Password</h3>
                    <div class="my-2">
                      <small
                        ><i
                          >*password minimal 8 karakter, mengandung minimal 1
                          huruf kapital dan 1 angka</i
                        >
                      </small>
                    </div>
                    <div class="error my-2" v-if="passwordFormatInvalid">
                      <p>Format password tidak sesuai.</p>
                    </div>
                    <div class="row">
                      <div class="col-9">
                        <vs-input
                          v-show="!showPass"
                          id="password"
                          type="password"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password"
                          required
                        />
                        <vs-input
                          v-show="showPass"
                          id="password"
                          type="text"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password"
                          required
                        />
                      </div>
                      <div class="col-3">
                        <b-button
                          @click="showPass = !showPass"
                          block
                          class="btn-show py-2"
                        >
                          <div v-show="!showPass">
                            <b-icon icon="eye"></b-icon>
                          </div>
                          <div v-show="showPass">
                            <b-icon icon="eye-slash"></b-icon>
                          </div>
                        </b-button>
                      </div>
                    </div>

                    <div class="error my-2" v-if="!$v.password.minLength">
                      <p>
                        Password minimal
                        {{ $v.password.$params.minLength.min }} karakter.
                      </p>
                    </div>

                    <h3 class="login-label">Konfirmasi Password</h3>

                    <div class="row">
                      <div class="col-9">
                        <vs-input
                          v-show="!showConfirmationPass"
                          id="password_confirmation"
                          type="password"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password_confirmation"
                          required
                        />
                        <vs-input
                          v-show="showConfirmationPass"
                          id="password"
                          type="text"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password_confirmation"
                          required
                        />
                      </div>
                      <div class="col-3">
                        <b-button
                          @click="showConfirmationPass = !showConfirmationPass"
                          block
                          class="btn-show py-2"
                        >
                          <div v-show="!showConfirmationPass">
                            <b-icon icon="eye"></b-icon>
                          </div>
                          <div v-show="showConfirmationPass">
                            <b-icon icon="eye-slash"></b-icon>
                          </div>
                        </b-button>
                      </div>
                    </div>
                    <div
                      class="error my-2"
                      v-if="!$v.password_confirmation.sameAsPassword"
                    >
                      <p>Password yang Anda masukkan tidak sama.</p>
                    </div>
                    <div class="mb-4"></div>
                    <div class="row">
                      <div class="col-lg-12 col-md-6 d-flex flex-row-reverse">
                        <div class="mx-2 terms">
                          <div class="mx-2 ml-n1">
                            <p>
                              Saya telah membaca, memahami, dan menyetujui
                              <b-link
                                href="#"
                                class="text-danger text-decoration-none"
                                target="_blank"
                                >Syarat dan Ketentuan.</b-link
                              >
                            </p>
                          </div>
                        </div>
                        <div class="align-items-center mt-1">
                          <vs-checkbox v-model="checked" color="secondary">
                          </vs-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-lg-12 col-md-6">
                        <b-button
                          block
                          type="submit"
                          :disabled="!checked || loading"
                          @click="handleSubmit()"
                          class="btn-tertiary py-2"
                        >
                          <div v-if="loading">
                            <div class="spinner-border spinner-border-sm"></div>
                            Loading
                          </div>
                          <div v-else>Daftar</div>
                        </b-button>
                      </div>
                    </div>
                  </form>
                  <b-row
                    class="justify-content-md-center dont-have-account-row"
                  >
                    <div class="dont-have-account">
                      <span
                        >Sudah punya akun?
                        <b-link class="login-link" to="/">Masuk</b-link>
                      </span>
                    </div>
                  </b-row>
                </div>
              </template>
            </b-card>
          </b-card-group>
          <b-row class="justify-content-md-center licensed">
            <div class="col-lg-3 col-md-6">
              <div class="license-text">
                <span>Terdaftar dan Diawasi oleh</span>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="ojk-logo ml-n4 mt-n1">
                <img
                  class="ojk-logo-image"
                  src="@/assets/img/login/ojk-logo.webp"
                  alt=""
                />
              </div>
            </div>
          </b-row>
        </div>
      </b-row>
    </b-container>
    <Footer4 />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import Footer4 from "@/components/partials/footer/Footer4.vue";
import { email, sameAs, minLength, maxLength } from "vuelidate/lib/validators";
import instance from "@/utils/api";

export default {
  name: "RegisterFromSales",
  metaInfo: {
    title: "Registrasi | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer4,
  },
  data() {
    return {
      email: "",
      mobile_phone: "",
      password: "",
      password_confirmation: "",
      sales_id: "",
      // successShow: false,
      emailIsExist: false,
      phoneIsExist: false,
      phoneAndEmailIsExist: false,
      passwordFormatInvalid: false,
      emtyForm: false,
      loading: false,
      processing: false,
      showPass: false,
      showConfirmationPass: false,
      checked: false,
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
    }),
  },

  validations: {
    email: {
      email,
    },
    mobile_phone: {
      minLength: minLength(10),
      maxLength: maxLength(13),
    },
    password: {
      minLength: minLength(8),
    },
    password_confirmation: {
      sameAsPassword: sameAs("password"),
    },
  },
  created() {
    var currentUrl = window.location.pathname;
    var sales = currentUrl.substring(
      currentUrl.lastIndexOf("/") + 1,
      currentUrl.length
    );

    if (sales === "densap") {
      this.sales_id = 3;
    } else if (sales === "aguset") {
      this.sales_id = 4;
    } else if (sales === "anapal") {
      this.sales_id = 5;
    } else if (sales === "ekky") {
      this.sales_id = 6;
    } else if (sales === "iqfa") {
      this.sales_id = 7;
    } else if (sales === "riawul") {
      this.sales_id = 8;
    } else if (sales === "pama") {
      this.sales_id = 9;
    } else if (sales === "srim") {
      this.sales_id = 11;
    } else if (sales === "franda") {
      this.sales_id = 30;
    } else if (sales === "oneasset") {
      this.sales_id = 31;
    } else if (sales === "feb002") {
      this.sales_id = 32;
    } else if (sales === "kun003") {
      this.sales_id = 33;
    } else if (sales === "tib") {
      this.sales_id = 34;
    } else if (sales === "sri004") {
      this.sales_id = 35;
    } else if (sales === "eli005") {
      this.sales_id = 36;
    } else {
      this.sales_id = null;
    }
  },
  methods: {
    handleSubmit() {
      if (!this.processing) {
        this.processing = true;
        let data = new FormData();
        data.append("name", this.email);
        data.append("email", this.email);
        data.append("mobile_phone", this.mobile_phone);
        data.append("password", this.password);
        data.append("password_confirmation", this.password_confirmation);
        data.append("sales_id", this.sales_id);
        data.append("role", "3");
        data.append("type", "2");

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        instance
          .post(`${this.app_url}2822`, data, axiosConfig) //staging

          .then((res) => {
            console.log("RESPONSE RECEIVED: ", res);

            this.emailIsExist = false;

            localStorage.setItem("checkEmail", this.email);
            localStorage.setItem("checkPassword", this.password);

            this.loading = !false;
            window.location.href = "/verification";
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            if (
              err.response.data.data.message ==
                "Isian email sudah ada sebelumnya." ||
              err.response.data.data.message ==
                "Isian email sudah ada sebelumnya.,Isian password wajib diisi.,Isian mobile phone wajib diisi." ||
              err.response.data.data.message ==
                "Isian email sudah ada sebelumnya.,Format isian password tidak valid."
            ) {
              this.processing = false;
              this.phoneIsExist = false;
              this.phoneAndEmailIsExist = false;
              this.emtyForm = false;
              this.passwordFormatInvalid = false;
              this.emailIsExist = err.response.data.data.message;
            } else if (
              err.response.data.data.message ==
                "Isian mobile phone sudah ada sebelumnya." ||
              err.response.data.data.message ==
                "Isian name wajib diisi.,Isian email wajib diisi.,Isian password wajib diisi.,Isian mobile phone sudah ada sebelumnya." ||
              err.response.data.data.message ==
                "Format isian password tidak valid.,Isian mobile phone sudah ada sebelumnya."
            ) {
              this.processing = false;
              this.emailIsExist = false;
              this.phoneAndEmailIsExist = false;
              this.emtyForm = false;
              this.passwordFormatInvalid = false;
              this.phoneIsExist = err.response.data.data.message;
            } else if (
              err.response.data.data.message ==
                "Isian email sudah ada sebelumnya.,Isian mobile phone sudah ada sebelumnya." ||
              err.response.data.data.message ==
                "Isian email sudah ada sebelumnya.,Format isian password tidak valid.,Isian mobile phone sudah ada sebelumnya." ||
              err.response.data.data.message ==
                "Isian email sudah ada sebelumnya.,Isian password wajib diisi.,Isian mobile phone sudah ada sebelumnya."
            ) {
              this.processing = false;
              this.emailIsExist = false;
              this.phoneIsExist = false;
              this.emtyForm = false;
              this.passwordFormatInvalid = false;
              this.phoneAndEmailIsExist = err.response.data.data.message;
            } else if (
              err.response.data.data.message ==
              "Format isian password tidak valid."
            ) {
              this.processing = false;
              this.emailIsExist = false;
              this.phoneIsExist = false;
              this.phoneAndEmailIsExist = false;
              this.emtyForm = false;
              this.passwordFormatInvalid = err.response.data.data.message;
            } else if (
              err.response.data.data.message ==
                "Isian name wajib diisi.,Isian email wajib diisi.,Isian password wajib diisi.,Isian mobile phone wajib diisi." ||
              err.response.data.data.message ==
                "Isian name wajib diisi.,Isian email wajib diisi.,Format isian password tidak valid.,Isian mobile phone wajib diisi." ||
              err.response.data.data.message ==
                "Isian name wajib diisi.,Isian email wajib diisi.,Isian mobile phone wajib diisi." ||
              err.response.data.data.message ==
                "Isian password wajib diisi.,Isian mobile phone wajib diisi." ||
              err.response.data.data.message ==
                "Isian password wajib diisi.,Isian mobile phone sudah ada sebelumnya." ||
              err.response.data.data.message ==
                "Isian email sudah ada sebelumnya.,Isian password wajib diisi.,Isian mobile phone sudah ada sebelumnya." ||
              err.response.data.data.message ==
                "Isian email sudah ada sebelumnya.,Isian password wajib diisi.,Isian mobile phone wajib diisi." ||
              err.response.data.data.message ==
                "Isian name wajib diisi.,Isian email wajib diisi.,Isian password wajib diisi."
            ) {
              this.processing = false;
              this.emailIsExist = false;
              this.phoneIsExist = false;
              this.phoneAndEmailIsExist = false;
              this.passwordFormatInvalid = false;
              this.emtyForm = err.response.data.data.message;
            } else {
              this.processing = false;
              this.emailIsExist = false;
              this.phoneIsExist = false;
              this.passwordFormatInvalid = false;
              this.phoneAndEmailIsExist = false;
              this.emtyForm = false;
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  background: #f3f4f6;
}
.register-container {
  padding-top: 140px;
  padding-bottom: 80px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #4b5563;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4f566b;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vs-con-input-label {
  width: 100%;
}

.back-nav {
  padding: 0px 240px 20px 308px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #e53e3e;
  }
}

.login-label {
  padding-top: 16px;
}

.forgot-password-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #6b7280;
  text-decoration: none;
}

.remember-me {
  padding-top: 16px;
  padding-bottom: 16px;
}

.custom-control-label {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #3c4257;
}

.custom-control-label:before {
  background-color: #e60012 !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e60012 !important;
}

.card-body {
  padding: 48px;
  box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
    0px 4px 6px rgba(107, 114, 128, 0.05);
  border-radius: 5px;
  width: 500px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

.dont-have-account {
  padding-top: 28px;
}

.login-link {
  font-family: $inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #e60012;
  text-decoration: none;
}

.licensed {
  padding-top: 30px;
}

.license-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1f2937;
}

.ojk-logo-image {
  width: 120px;
}

.modal-register {
  z-index: 99999;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: black;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #4b5563;
  }
}
@media only screen and (max-width: 768px) {
  .login-container {
    padding-bottom: 60px;
  }
  .back-nav {
    padding: 0px 240px 20px 0px;
  }
  .card-body {
    width: 100%;
  }
  .forgot-password-link {
    font-size: 10px;
  }
  .dont-have-account-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .licensed {
    text-align: center;
  }
  .ojk-logo-image {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1366px) {
  .login-container {
    padding-bottom: 300px;
  }
}
</style>